<template>
  <div class="main">
    <div class="box">
      <div class="content">
        <div style="display: flex;justify-content: space-between;align-items: center;">
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          style="margin-left: 20px;">
          <el-breadcrumb-item :to="{ path: '/report' }">工作汇报</el-breadcrumb-item>
          <el-breadcrumb-item>项目详情</el-breadcrumb-item>
        </el-breadcrumb>

        <el-button type="primary" style="margin-right: 20px;" @click="$router.go(-1)">返回</el-button>
      </div>
        <div
          style="margin-top: 20px; margin-bottom: 10px; flex: 1"
          class="tableaa"
        >
          <el-table
            style="width: 98%; margin: 0 auto"
            height="96%"
            :header-cell-style="{
              'text-align': 'center',
              background: 'rgb(240, 247, 253)',
            }"
            :data="tableData"
            border
          >
            <el-table-column
              type="index"
              width="60"
              align="center"
              label="序号"
            >
            </el-table-column>
            <el-table-column
              v-if="admin == '00000000-0000-0000-0000-000000000000'"
              prop="OrganizationName"
              label="组织名称"
              min-width="80"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="项目名称"
              min-width="168"
              align="center"
              :sortable="true"
              :sort-method="sortByVesselName"
            >
              <template slot-scope="scope">
                <span @click="Projectdetails(scope.row)">{{
                  scope.row.Name
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="No"
              sortable
              label="项目编号"
              width="120"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="Leader"
              label="负责人"
              width="100"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="Admin"
              label="管理员"
              width="120"
            >
            </el-table-column>
            <el-table-column
              align="center"
              label="单位"
              prop="LDeptName"
              min-width="120"
            >
            </el-table-column>
            <el-table-column label="起止时间" width="160" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.StartDate.substr(0, 10) }}</p>
                <p>~</p>
                <p>{{ scope.row.EndDate.substr(0, 10) }}</p>
              </template>
            </el-table-column>
            <el-table-column label="项目周期" width="100" align="center">
              <template slot-scope="scope">
                <span>{{ scope.row.Cycle }}</span>
              </template>
            </el-table-column>
            <el-table-column label="项目状态" width="100" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.State }}</p>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="194">
              <template slot-scope="scope">
                <div style="display: flex; flex-wrap: wrap">
                  <el-link
                    :underline="false"
                    style="margin-right: 11px"
                    @click="Projectdetails(scope.row)"
                    ><i class="el-icon-search"></i>查看</el-link
                  >
                  <add-project
                    :formType="2"
                    :editForm="scope.row"
                    v-if="
                      admin != '00000000-0000-0000-0000-000000000000' &&
                      RoleType == 'D'
                    "
                  ></add-project>
                  <el-link
                    v-if="
                      admin != '00000000-0000-0000-0000-000000000000' &&
                      RoleType == 'D'
                    "
                    :underline="false"
                    @click="DeleteProject(scope.row)"
                    ><i class="el-icon-delete"></i>删除</el-link
                  >
                  <!-- v-has="'Project/Delete'" -->
                  <el-link
                    v-if="
                      admin != '00000000-0000-0000-0000-000000000000' &&
                      RoleType == 'D'
                    "
                    :disabled="scope.row.IsRtc == false"
                    :underline="false"
                    style="margin-top: 10px"
                    @click="setRandom(scope.row)"
                    ><i
                      :class="{ iscolor: scope.row.IsRtc == false }"
                      class="el-icon-edit-outline"
                    ></i
                    ><span :class="{ iscolor: scope.row.IsRtc == false }"
                      >RTC研究设置</span
                    ></el-link
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div style="width: 98%; margin: 0 auto">
          <Pagination
            :total="total"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.rows"
            @pagination="GetList"
          ></Pagination>
        </div>
      </div>
    </div>

    <div>
      <el-dialog :visible.sync="HospitalDialog" width="700">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">参与单位</span>
        </div>
        <el-table
          ref="multipleTable"
          :data="HospitalData"
          tooltip-effect="dark"
          border
          height="330"
          style="width: 100%"
          :header-cell-style="{ background: 'rgb(240, 247, 253)' }"
        >
          <el-table-column label="序号" type="index" align="center" width="80">
          </el-table-column>
          <el-table-column
            label="参与单位"
            min-width="150"
            align="center"
            prop="HospitalName"
          >
          </el-table-column>
        </el-table>
        <!-- <pagination
            :total="totalHospitalList"
            :page.sync="HospitalList.PageIndex"
            :limit.sync="HospitalList.PageSize"
            @pagination="getList"
          >
          </pagination> -->
        <div class="btn" style="margin-top: 32px">
          <el-button @click="HospitalDialog = false">关闭</el-button>
        </div>
      </el-dialog>
    </div>
    <div>
      <el-dialog :visible.sync="RandomDialog" width="900" v-if="RandomDialog">
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">设置RTC研究</span>
        </div>
        <random-ization
          :projectId="projectId"
          @random="ization"
        ></random-ization>
      </el-dialog>
    </div>
  </div>
</template>
  
  <script>
import ProjectManagement from "@/api/ProjectManagement.js";
import { local } from "@/utils/storage";
import AddProject from "@/components/projectManagement/AddProject";
import RandomIzation from "@/components/projectManagement/RandomIzation";
import Pagination from "@/components/Pagination/index";
import WorkReport from "../../api/WorkReport";
export default {
  name: "HomePage",
  data() {
    return {
      RandomDialog: false, // 随机化弹框
      HospitalDialog: false,
      totalHospitalList: 20,
      HospitalList: {
        Key: "",
        PageIndex: 1,
        PageSize: 5,
      },
      HospitalData: [
        {
          id: 1,
          HospitalName: "老年女性盆底功能障碍早期识别问卷",
        },
      ],
      totalRoot: 0,
      listRoot: {
        Key: "",
        PageIndex: 1,
        PageSize: 5,
      },
      rootData: [
        {
          id: 1,
          questionnaireName: "老年女性盆底功能障碍早期识别问卷",
          rootId: 111,
          Y: "是",
        },
      ],
      guanliandialog: false,
      timer: "", //定义一个定时器的变量
      currentTime: new Date(), // 获取当前时间
      UserName: local.get("UserName"),
      TitleName:
        local.get("TitleName") !== "undefined" ? local.get("TitleName") : "",
      admin: local.get("UserId"),
      RoleType: local.get("RoleType"),
      Sex: local.get("Sex"),
      tableData: [], // 表格数据
      total: 0,
      listQuery: {
        Key: "",
        page: 1,
        rows: 10,
      },
      Summary: {},
      projectId: "",
    };
  },
  computed: {
    date(start, end) {
      let day = Date.parse(end) - Date.parse(start);
      let days = parseInt(day / (1000 * 60 * 60 * 24));
      return days;
    },
  },
  components: {
    AddProject,
    RandomIzation,
    Pagination,
  },
  created() {
    this.GetList();
  },
  methods: {
    //通过文字进行排序
    sortByVesselName(obj1, obj2) {
      if (
        [obj1.Name, obj2.Name][0] ==
        [obj1.Name, obj2.Name].sort((a, b) =>
          a.localeCompare(b, "zh", { sensitivity: "accent" })
        )[0]
      ) {
        return -1;
      } else {
        return 1;
      }
    },
    ization(data) {
      this.RandomDialog = data;
    },
    //新增项目
    Addfather(data) {
      this.tableData.unshift(data);
    },
    //编辑项目
    Editfather(data) {
      this.tableData = this.tableData.map((item) => {
        if (item.Id == data.Id) {
          item = data;
        }
        return item;
      });
    },
    //查看
    Projectdetails(row) {
      // 打开查看项目弹框
      console.log(row);
      local.set("ProjectName", row.Name);
      local.set("IsRtc", row.IsRtc);
      this.$store.commit("ElTabsFn", "first");
      this.$router.push({
        path: "/seeProject",
        query: {
          Id: row.Id,
        },
      });
    },
    DeleteProject(row) {
      //打开删除项目弹框
      this.$confirm("此操作将永久删除该项目, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const parameter = [];
        parameter.push(row.Id);
        ProjectManagement.DeleteProjectData(parameter).then((res) => {
          if (res.data.Status == 1) {
            this.tableData = this.tableData.filter((item) => {
              return item.Id != row.Id;
            });
          } else {
            alert(res.data.Message);
          }
        });
        this.$message({
          type: "success",
          message: res.data.Message,
        });
      });
    },
    setRandom(row) {
      // 打开随机化弹框
      console.log(row);
      this.projectId = row.Id;
      this.RandomDialog = true;
    },
    //项目列表
    async GetList() {
      const params = {
        ...this.listQuery,
        ...this.$route.query,
      };
      WorkReport.GetPatientList(params).then((res) => {
        console.log(res, "项目列表数据");
        this.tableData = res.data.rows;
        this.total = res.data.total;
      });
    },
    //项目信息总汇
    async QueryProjectSummary() {
      ProjectManagement.QueryProjectSummaryData().then((res) => {
        if (res.data.Status == 1) {
          this.Summary = res.data.Data;
        } else {
          alert(res.data.Message);
        }
        console.log(res, "项目信息综合");
      });
    },
    guanlian(row) {
      this.guanliandialog = true;
    },
    HospitalListDialog(row) {
      this.HospitalDialog = true;
    },
    // 搜索
    search() {
      console.log("search", this.listQuery.Key);
      this.GetList();
    },

    // 防抖函数
    debounce(fn) {
      let t = null;
      //往这里传e
      return function (e) {
        if (t) {
          clearTimeout(t);
        }

        t = setTimeout(function () {
          //再带给fn
          fn(e);
        }, 1000);
      };
    },

    async getTimer() {
      // 获取当前时间
      this.timer = setInterval(() => {
        this.currentTime = new Date();
      }, 1000);
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer); // 在Vue实例销毁前，清除我们的定时器
    }
  },
};
</script>
  
  <style scoped lang="scss">
@import "../../styles/mixin.scss";
.iscolor {
  color: #666 !important;
}
.main {
  width: 100%;
  min-width: 1440px;
  height: 100%;
  overflow: hidden;
  .box {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    margin: 0 auto;
    border-radius: 10px;
    .content {
      width: 100%;
      height: 100%;
      margin-top: 20px;
      background: #fff;
      padding: 20px 0px 10px 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      .content-header {
        width: 98%;
        height: 120px;
        display: flex;
        justify-content: space-between;
        background: #fbfbfd;
        border: 1px solid #e9e9e9;
        margin: 0 auto 20px auto;
        padding: 12px 81px 0px 50px;
        box-sizing: border-box;
        background-image: url("../../assets/image/headerBg.png");
        background-size: cover;
        background-repeat: no-repeat;
        border-radius: 20px;
        .header-left {
          height: 90px;
          .p-header-title {
            font-size: 24px;
            font-weight: 400;
            line-height: 40px;
            color: #333333;
            margin-top: 13px;
            margin-left: 10px;
          }
          span {
            @include common_span;
            margin-left: 10px;
          }
        }
        .header-right {
          width: 120px;
          text-align: center;
          p {
            width: 120px;
            @include common_span;
            color: #3388ff;
          }
          span {
            font-size: 34px;
            color: #3388ff;
          }
        }
        .header-right2 {
          width: 120px;
          text-align: center;
          p {
            width: 120px;
            @include common_span;
            color: #ff9900;
          }
          span {
            font-size: 34px;
            color: #ff9900;
          }
        }
      }
      .project-query-add {
        width: 98%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        span {
          @include common_span;
        }
        .query-input {
          width: 240px;
          height: 34px;
          margin-right: 20px;
          .el-input__inner {
            width: 240px;
            height: 34px;
            @include add-size($font_size_16);
          }
        }
        .query {
          ::v-deep .el-button {
            @include common_button_primary;
          }
        }
      }
    }
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
  .main {
    width: 100%;
    min-width: 0 !important;

    .content {
      display: flex;
      flex-direction: column;

      .table {
        flex: 1;
      }

      .tableaa {
        margin-bottom: 0 !important;
      }
    }

    .header-left {
      width: 250px;
    }
    .content-header {
      img {
        height: 85% !important;
      }
      .p-header-title {
        font-size: 18px !important;
        line-height: 24px !important;
      }
    }
  }
}
</style>
  